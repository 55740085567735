'use client'

import { clsx } from '@sapphireui/core/utils'
import { useToggle } from '@sapphireui/core/hooks'
import { ButtonIcon, DialogHeader, type ButtonIconProps } from '@sapphireui/react'

import {
	Logo,
	Dialog,
	TextLink,
	DialogBody,
	CompanyText,
	DialogFooter,
	DialogCloseButton,
	type DialogProps,
} from '@seeurcargo/shared'
import { Icon } from '@seeurcargo/icons'

import { internalPageConfig } from '~/config/page'

type NavButtonProps = ButtonIconProps & {
	modalProps?: DialogProps
}

const NavButton = ({ modalProps, ...props }: NavButtonProps) => {
	const [isOpen, { close, set, open }] = useToggle()

	return (
		<>
			<ButtonIcon
				size="md"
				title="Menu"
				variant="outlined"
				aria-label="Menu"
				className="!rounded-full"
				icon={<Icon name="menu" />}
				{...props}
				onClick={open}
			/>
			<Dialog
				position="right"
				{...modalProps}
				open={isOpen}
				onChange={set}
				className={clsx(
					'!py-6 !px-8 sm:!px-10 sm:min-w-[450px] sm:max-w-[450px]',
					modalProps?.className,
				)}
			>
				<DialogHeader className="w-full flex justify-end">
					<DialogCloseButton
						size="sm"
						className="rounded-lg"
					/>
				</DialogHeader>

				<DialogBody
					as="ul"
					className="flex-1 flex flex-col justify-center"
				>
					{[
						internalPageConfig.index,
						internalPageConfig.posts,
						internalPageConfig.faq,
						internalPageConfig.contact,
						internalPageConfig.legal,
						internalPageConfig.download,
					].map((link) => (
						<li
							key={link.id}
							onClick={close}
						>
							<TextLink
								href={link.href}
								className="w-full text-gray-900 font-medium text-2xl py-3"
							>
								{link.name}
							</TextLink>
						</li>
					))}
				</DialogBody>

				<DialogFooter className="flex items-center gap-x-3">
					<Logo size="sm" />
					<CompanyText />
				</DialogFooter>
			</Dialog>
		</>
	)
}

export { NavButton }

import { clsx } from '@sapphireui/core/utils'
import { Title, Button } from '@sapphireui/react'

import { LegalLinks } from '@seeurcargo/shared'

import type { StyleProps } from '~/lib/types'
import { internalPageConfig } from '~/config/page'

type CookiesBannerProps = StyleProps & {
	show?: boolean
	confirm?: (event: React.FormEvent<HTMLFormElement>) => unknown
}

const CookiesBanner = ({ style, confirm, className, show = false }: CookiesBannerProps) => {
	if (!show) {
		return null
	}

	return (
		<form
			style={style}
			className={clsx(
				'h-fit',
				'mx-auto py-5 px-5 bottom-0 w-full max-w-5xl flex items-center gap-4 max-md:gap-y-3 bg-content2/80 backdrop-blur-lg rounded-xl',
				'max-md:flex-col',
				className,
			)}
			onSubmit={(event) => {
				event.preventDefault()
				confirm?.(event)
			}}
		>
			<Title
				size={32}
				className="select-none self-start"
			>
				🍪
			</Title>

			<LegalLinks
				links={[
					internalPageConfig.privacyPolicyProccesing,
					internalPageConfig.userAgreement,
				]}
				className="max-md:mb-3 max-w-3xl text-sm leading-snug max-md:leading-normal cursor-default"
			/>

			<Button
				auto
				size="sm"
				type="submit"
				color="foreground"
				className="ml-auto self-start max-md:w-full md:self-center px-4"
			>
				Понимаю
			</Button>
		</form>
	)
}

export { CookiesBanner }
export type { CookiesBannerProps }

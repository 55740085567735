import { DASHBOARD_URL } from '~/lib/env'

type PageConfig = {
	id: string
	name: string
	href: string | ((...args: any[]) => string)

	external?: boolean
}

const internalPageConfig = {
	download: {
		id: 'download',
		href: '/download',
		name: 'Скачать',
		external: false,
	},
	index: {
		id: '/',
		href: '/',
		name: 'Главная',
		external: false,
	},
	faq: {
		id: 'faq',
		name: 'FAQ',
		href: '/faq',
		external: false,
	},
	legal: {
		id: 'legal',
		name: 'Документы',
		href: '/faq/legal',
		external: false,
	},
	hints: {
		id: 'hints',
		name: 'Подсказки',
		href: '/faq/hints',
		external: false,
	},
	tutorial: {
		id: 'tutorial',
		name: 'Инструкции',
		href: '/faq/tutorial',
		external: false,
	},
	posts: {
		id: 'posts',
		href: '/posts',
		name: 'Новости',
		external: false,
	},
	contact: {
		id: 'contact',
		href: '/contact',
		name: 'Контакты',
		external: false,
	},

	userAgreement: {
		id: 'user-agreement',
		name: 'Пользовательское соглашение',
		href: '/legal/user-agreement.pdf',
		external: false,
	},
	privacyPolicy: {
		id: 'privacy-policy',
		name: 'Политика конфиденциальности',
		href: '/legal/privacy-policy.pdf',
		external: false,
	},
	paymentMethods: {
		id: 'payment-methods',
		name: 'Информация о способах оплаты',
		href: '/payment-methods.pdf',
		external: false,
	},
	privacyPolicyProccesing: {
		id: 'privacy-policy-proccesing',
		name: 'Политика обработки персональных данных',
		href: '/legal/privacy-policy.pdf',
		external: false,
	},
	privacyPolicyAgreement: {
		id: 'privacy-policy-agreement',
		name: 'Согласие на обработку персональных данных',
		href: '/legal/privacy-policy.pdf',
		external: false,
	},
	offerAgreement: {
		id: 'offer-agreement',
		name: 'Публичный договор',
		href: '/legal/offer-agreement.pdf',
		external: false,
	},
} satisfies Record<string, PageConfig>

const dashboardPageConfig = {
	index: {
		id: 'index',
		name: 'Панель управления',
		href: new URL('/', DASHBOARD_URL).toString(),
		external: true,
	},
	sign: {
		id: 'sign',
		name: 'Войти',
		href: new URL('/sign', DASHBOARD_URL).toString(),
		external: true,
	},
} satisfies Record<string, PageConfig>

export { internalPageConfig, dashboardPageConfig }
export type { PageConfig }

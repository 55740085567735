import(/* webpackMode: "eager", webpackExports: ["Title","Text","Button"] */ "__barrel_optimize__?names=Button,Text,Title!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Button!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonIcon"] */ "__barrel_optimize__?names=ButtonIcon!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Responsive","Collapse","Text"] */ "__barrel_optimize__?names=Collapse,Responsive,Text!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Text","Hint"] */ "__barrel_optimize__?names=Hint,Text!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Hint!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Spacer!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Text!=!/app/sapphireui/react/src/index.ts");
;
import(/* webpackMode: "eager" */ "/app/apps/docs/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/apps/docs/app/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["NavButton"] */ "/app/apps/docs/src/components/(buttons)/NavButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/docs/src/components/Cookies/Cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/apps/docs/src/components/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/components/(text)/styles.module.css");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/components/AdaptiveDialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/components/BannerBeta/styles.module.css");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/components/Error/styles.module.css");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/components/Folder.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/components/Scrollable/styles.module.css");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/hooks/useMediaQuery.ts");
;
import(/* webpackMode: "eager" */ "/app/packages/shared/src/hooks/useResponsive.tsx");

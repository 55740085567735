'use client'

import { useReportWebVitals } from 'next/web-vitals'

import { NODE_ENV } from '~/lib/env'

const WebVitals = () => {
	useReportWebVitals((metric) => {
		if (NODE_ENV !== 'development') {
			return null
		}
		console.log(metric)
	})

	return null
}

export { WebVitals }

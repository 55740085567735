'use client'

import { useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { clsx, TRANSTION_TRANSLATES, type StyleProps } from '@sapphireui/core/utils'

import { useCookie } from '~/hooks/useCookie'

import { CookiesBanner } from './Cookies.Banner'

type CookiesProps = StyleProps & {
	hide?: boolean

	offset?: string | number
}

const Cookies = ({ style, className, hide = false, offset = '1rem' }: CookiesProps) => {
	const [agreed, setAgreed] = useCookie<boolean>(COOKIES_AGREED_KEY)

	const confirm = useCallback(() => {
		setAgreed(true, {
			sameSite: 'lax',
			maxAge: ONE_YEAR_IN_MS,
		})
	}, [setAgreed])

	const show = !hide && !agreed

	return (
		<AnimatePresence initial={show}>
			{show ? (
				<motion.div
					key="cookies"
					exit="exit"
					initial="exit"
					animate="enter"
					variants={TRANSTION_TRANSLATES.bottom}
					style={{
						...style,
						left: offset,
						right: offset,
						paddingBottom: offset,
					}}
					className={clsx('z-50 fixed bottom-0 h-fit', className)}
				>
					<CookiesBanner
						show
						confirm={confirm}
					/>
				</motion.div>
			) : null}
		</AnimatePresence>
	)
}

const COOKIES_AGREED_KEY = 'cookies_agreed'
const ONE_YEAR_IN_MS = 365 * 24 * 60 * 1000 * 1000

export { Cookies }
export type { CookiesProps }

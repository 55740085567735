// export const BETA = false
export const BETA = process.env.BETA === 'true' ? true : false

export const NODE_ENV = process.env.NODE_ENV as string
export const DASHBOARD_URL = (process.env.DASHBOARD_URL ||
	process.env.NEXT_PUBLIC_DASHBOARD_URL) as string

export const PROJECT_NAME = process.env.PROJECT_NAME || 'SeeUrCargo'

export const DOMAIN = process.env.DOMAIN as string
export const DOMAIN_URL = `https://${DOMAIN}`
